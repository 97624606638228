::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,0) !important;
}

@font-face {
    font-family: 'Copperplate-Bold1';
    src: url('./font/copperplate.ttf');
}

@font-face {
    font-family: 'Copperplate-Bold-x';
    src: url('./font/copperplate.ttf');
}

@font-face {
    font-family: 'Copperplate Gothic-Light1';
    src: url('./font/cour.ttf');
}

@font-face {
    font-family: 'Arial-Regular';
    src: url('./font/courbd.ttf');
}
