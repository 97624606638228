* {
  padding: 0; 
  margin: 0;
}
.corsor-pointer {
  cursor: pointer;
}


body {
  scrollbar-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
  /* 第一个方块颜色，第二个轨道颜色(用于更改火狐浏览器样式) */
  scrollbar-width: thin;
  /* 火狐滚动条无法自定义宽度，只能通过此属性使滚动条宽度变细 */
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;
}

body::-webkit-scrollbar { width: 0 !important }